import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { map, catchError } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  apiURL = environment.apiURL + '/users';

  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;

  constructor(private http: HttpClient) {
    this.currentUserSubject = null;
    this.currentUser = null;
    const currentUser = localStorage.getItem('currentUser');
    if (currentUser !== 'undefined') {
      this.currentUserSubject = new BehaviorSubject<any>(
        JSON.parse(currentUser)
      );
      this.currentUser = this.currentUserSubject.asObservable();
    }
  }

  public get currentUserValue(): any {
    return this.currentUserSubject.value;
  }

  login(username: string, password: string) {
    let url = this.apiURL + '/login';

    const body = {
      email: username,
      password: password,
    };

    return this.http.post<any>(url, body).pipe(
      map((user) => {
        if (user && user.token) {
          localStorage.setItem('currentUser', JSON.stringify(user));
          this.currentUserSubject.next(user);
        }

        return user;
      })
    );
  }

  logout() {
    sessionStorage.clear();
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
  }

  createUser(user) {
    let url = this.apiURL + '/signup';

    return this.http.post(url, user);
  }

  resetPassword() {
    // TODO
  }
}
