import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  FormControl,
  FormGroupDirective,
  NgForm,
  Validators,
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { AuthService } from '../services/auth.service';
import { HotToastService } from '@ngneat/hot-toast';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, ErrorStateMatcher {
  @ViewChild('loginForm', { static: false }) loginForm: NgForm;
  @ViewChild('registerForm', { static: false }) registerForm: NgForm;
  @ViewChild('forgotForm', { static: false }) forgotForm: NgForm;

  mode: string = '';

  isNewUser: boolean = false;
  forgotPassword: boolean = false;
  userEmail: string;
  userPassword: string;

  newUser: any = {
    email: '',
    first_name: '',
    last_name: '',
    date_of_birth: '',
    gender: '',
  };

  isLoading: boolean = false;
  loginError: boolean = false;
  error: string;

  constructor(
    private authService: AuthService,
    private toastService: HotToastService,
    @Inject(MAT_DIALOG_DATA) public data,
    public dialog: MatDialog
  ) {}

  isErrorState(
    control: FormControl,
    form: FormGroupDirective | NgForm
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }

  ngOnInit(): void {
    console.log(this.data);

    if (this.data.mode === 'login') {
      this.isNewUser = false;
      this.forgotPassword = false;
    } else if (this.data.mode === 'register') {
      this.isNewUser = true;
      this.forgotPassword = false;
    }
  }

  login() {
    this.loginError = false;

    for (let inner in this.loginForm.controls) {
      this.loginForm.controls[inner].markAllAsTouched();
      this.loginForm.controls[inner].markAsDirty();
    }
    if (this.loginForm.valid) {
      this.isLoading = true;

      this.authService.login(this.userEmail, this.userPassword).subscribe(
        (res: any) => {
          this.isLoading = false;
          this.toastService.success(`You're now logged in!`);

          this.dialog.closeAll();
        },
        (error) => {
          this.isLoading = false;
          this.toastService.error(error.error.message);
        }
      );
    }
  }

  reset() {
    for (let inner in this.forgotForm.controls) {
      this.forgotForm.controls[inner].markAllAsTouched();
      this.forgotForm.controls[inner].markAsDirty();
    }
    if (this.forgotForm.valid) {
      console.log('Forgot Submitted!');
      this.dialog.closeAll();
    }
  }

  register() {
    for (let inner in this.registerForm.controls) {
      this.registerForm.controls[inner].markAllAsTouched();
      this.registerForm.controls[inner].markAsDirty();
    }
    if (this.registerForm.valid) {
      this.authService.createUser(this.newUser).subscribe((res: any) => {
        console.log(res);

        this.dialog.closeAll();
      });
    }
  }

  logout() {}
}
