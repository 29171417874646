import { Component, OnInit } from '@angular/core';
import { faAngleRight, faAngleDown } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-content-wrap',
  templateUrl: './content-wrap.component.html',
  styleUrls: ['./content-wrap.component.scss'],
})
export class ContentWrapComponent implements OnInit {
  faAngleRight = faAngleRight;
  faAngleDown = faAngleDown;

  isMenCollapsed: boolean = true;
  isWomenCollapsed: boolean = true;
  isKidsCollapsed: boolean = true;

  constructor() {}

  ngOnInit(): void {}
}
