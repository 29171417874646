import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
})
export class BreadcrumbsComponent implements OnInit {
  @Input() route: string;
  @Input() customRoute: string;
  @Input() section: string;
  breadcrumbs: any[];

  constructor(private router: Router) {}

  ngOnInit(): void {
    if (!this.customRoute) {
      this.breadcrumbs = this.route.split('/');
      this.breadcrumbs[0] = 'home';
    } else {
      this.breadcrumbs = this.customRoute.split('/');
      this.breadcrumbs[0] = 'home';

      this.breadcrumbs.pop();
      this.breadcrumbs.pop();

      this.breadcrumbs.push(this.section);
      console.log(this.customRoute);
    }
  }

  navigate(index) {
    if (index !== this.breadcrumbs.length - 1) {
      let url = '';
      for (let i = 1; i < index + 1; i++) {
        url += '/' + this.breadcrumbs[i];
      }
      this.router.navigate([url]);
    }
  }
}
